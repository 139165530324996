.contact-page {
  width: 100%;
  height: 100vh; /* Adjust as needed */
  position: relative; /* Ensure child elements can be positioned absolutely */
  overflow: hidden; /* Prevent overflow if necessary */
}

.contact-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65%; /* Half of the container height */
  background: url('../assets/map.jpg') no-repeat center center;
  background-size: cover;
  z-index: -1; /* Ensure it is behind other content */
}

.contact-page::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%; /* Half of the container height */
  background-color: white; /* Solid color for the bottom half */
  z-index: -1; /* Ensure it is behind other content */
}

.contact-form-container {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 12%;
}

.contact-form-container h2 {
  margin-bottom: 20px;
}

.contact-infomation {
  background: linear-gradient(to right, rgba(34, 230, 135, 0.8), rgba(13, 219, 209, 0.8));
  color: #fff;
  padding: 30px;
  border-radius: 8px;
}

.contact-info h3 {
  margin-top: 0;
}

.submit-button {
  background-color: #A7CE3A;
  border-radius: 20px;
  width: 100%;
  max-width: 200px;
  height: 50px;
  border: none;
}

.button-group-contact {
  gap: 10px;
  margin-top: 20px;
  text-align: center; /* Center the button on smaller screens */
}

.submit-button:hover {
  background-color: #8AB631;
}

.submit-button:active {
  background-color: #A7CE3A !important; /* Change this to your desired color */
  outline: none;
  box-shadow: none;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.social-icons:hover {
  color: #ff5e62;
}

/* Custom form controls styling */
.contact-form-container .form-control {
  border-radius: 0;
  border: 1px solid #ced4da;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
}

.contact-form-container .form-control:focus {
  border-color: #80bdff;
  box-shadow: none;
}

.contact-form-container .form-control::placeholder {
  color: #adb5bd;
}

/* Media Queries for Responsive Design */
@media (max-width: 767px) {
  .contact-infomation, .contact-form-container {
      margin-bottom: 30px;
  }
  .contact-page{
    margin-bottom: 80px;
  }
  .contact-page {
    position: relative;
    background-color: #f8f9fa;
    padding: 50px 0;
    background-image: url('../assets/map.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:  100vh; /* Adjust this as needed to cover 75% of the viewport height */
  }
}

@media (max-width: 575px) {
  .contact-page{
    margin-bottom: 25px;
  }

  .submit-button {
      width: 100%;
  }
  
  .contact-form-container {
      padding: 20px;
  }

  .contact-infomation {
      padding: 20px;
  }
  
  /* Adding margin between button and contact information for smaller screens */
  .button-group-contact {
      margin-bottom: 20px; /* Adjust this value as needed */
  }
  .contact-page {
    position: relative;
    background-color: #f8f9fa;
    padding: 50px 0;
    background-image: url('../assets/map.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:  100vh; /* Adjust this as needed to cover 75% of the viewport height */
  }
}