.nav-tabs .nav-link {
    color: black;
}

.nav-tabs .nav-link.active {
    color: #FECC13;
}
  
  .pagination .page-item .page-link {
    color: black;
    border: 1px solid black;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #FECC13;
    border-color: black;
    color: black;
  }
  