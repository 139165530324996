.contact-info {
    padding: 20px ;
    background-color: #f8f9fa;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    color: #A7CE3A
  }
  
  .contact-item p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  