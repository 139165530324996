.card-title-home {
    font-weight: 700;
    font-size: 30px;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
}
.prod-margin {
    margin-top: 30px;
    margin-bottom: 30px;
}
.card {
    padding: 2rem;
    background-color: #fff;
    height: 550px;
    padding: 0px !important;
}
.card-body-prod {
    padding: 0px;
}
.card img {
    height: 100%;
    object-fit: cover;
}
.button-group {
    gap: 10px;
    margin-top: 20px;
}
.explore-button {
    background-color: #A7CE3A;
    border-radius: 20px;
    width: 200px;
    height: 50px;
    border: none;
}
.explore-button:hover {
    background-color: #8AB631;
}
.explore-button:active {
    background-color: #A7CE3A !important;
    outline: none;
    box-shadow: none;
}

/* Media Queries */
@media (max-width: 767px) {
    .card {
        height: auto;
        padding: 1rem;
    }

    .card-title-home {
        font-size: 24px;
    }

    .explore-button {
        width: 100%;
        height: 40px;
    }

    .button-group {
        justify-content: center;
    }
}
