.works-block {
    background-color: #1B2431;
    padding-bottom: 30px;
    padding-top: 30px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.grid-item {
    position: relative;
    overflow: hidden;
    height: 100%;
    cursor: pointer;
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.grid-item .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
}

.grid-item:hover .overlay {
    opacity: 1;
}

.overlay div {
    margin: 4px 0;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 1000;
    width: 70%;
    height: auto;
    outline: none;
    border-radius: 8px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.modal-image {
    width: 100%;
    height: auto;
}

.close-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

/* Responsive Grid */
@media (max-width: 992px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
